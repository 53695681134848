/**
 * Created by lsubramaniam on 10/29/17.
 */
// Component is a decorator imported from @angular/core
import {Component, OnInit, ViewChild} from '@angular/core';
import {SiteManagerService} from './site-manager.service';
import {concat, Observable} from 'rxjs';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
import {Md5} from 'ts-md5/dist/md5';
import {Title} from '@angular/platform-browser';
import {GlobalDataService} from '../../common/util/globaldata.service';
import {UtilityService} from '../../common/util/utils.service';
import OptInResponseModel from '../../models/optin-response-model';
import SiteDetailModel from '../../models/site-detail-model';
import {TrackerService} from '../../services/tracker-service';
import {ActivityType} from '../../models/activity-type';
import {Link} from '../../models/link.interface';
import {EventList} from '../../common/eventlistener/event-list';
import {UserOptinInfo} from '../../models/user-optin-info';
import {HttpClient} from '@angular/common/http';
import * as uuid from 'uuid';
import {LoggerService} from '../../services/logger.service';
import {environment} from '../../../environments/environment';
import {ExternalJsSupportService} from 'src/app/services/externalJsSupport.service';
import { FormTrackingPlugin, enableFormTracking } from '@snowplow/browser-plugin-form-tracking';
import { LinkClickTrackingPlugin, enableLinkClickTracking } from '@snowplow/browser-plugin-link-click-tracking';
import { newTracker, enableActivityTracking, trackPageView } from '@snowplow/browser-tracker';
import { PerformanceTimingPlugin } from '@snowplow/browser-plugin-performance-timing';
import { formOptions } from 'src/app/models/sp-constants';
import { render } from 'velocityjs';

declare var $: any;
declare var mixpanel: any;
declare const loadGTMScript: Function;
declare const EventService: any;
declare const Osano: any;

// use of the decorator to register the component
@Component({
  // html element
  selector: 'site-manager', // <- separated by comma, not semicolomn like in Java
  // the template can be inline or refer to an external file
  templateUrl: './site-manager.component.html',
  providers: [
    SiteManagerService,
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    }
  ]
})
// name of the component, exported to be reusable
export class SiteManagerComponent implements OnInit {
  _siteDetail = new SiteDetailModel();
  htmlContent = '';
  currentCssLinks: Link[] = [];
  currentCssInline = '';
  currentJsLinks: Link[] = [];
  currentJsInline = '';
  styleIds: string[] = [];
  jsIds: string[] = [];
  _optInResponse = new OptInResponseModel();
  isProcessing = false;
  private static readonly CAMPAIGN_LINKS_SEPARATOR = ';';


  constructor(private siteManagerService: SiteManagerService,
              private utilityService: UtilityService,
              private globalDataService: GlobalDataService,
              private titleService: Title,
              private trackerService: TrackerService,
              private logger: LoggerService,
              private http: HttpClient,
              private externalJs: ExternalJsSupportService) {
  }

  ngOnInit(): void {
    this.globalDataService.sessionId = uuid.v4();
    this.utilityService.setUpQueryStringDetails();
    this.setupSiteDetails();
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  public onNextPageClickEvent(action: string) {
    this.utilityService.loadNextAsset(action);
    this.loadAsset();
    this.trackerService.track(ActivityType.click);
  }

  public async onSingleOptinClickEvent(event: UserOptinInfo) {
    this.trackerService.track(ActivityType.click);
    this.isProcessing = true;
    this.globalDataService.email = event.email;
    // Dispatch Event For SingleOptinClick
    EventService.dispatchEvent(
      EventList.SingleOptinClick, null, event
    );
    if (this.globalDataService.asset.isCoiOptin) {
      this.logger.log(`Started Processing COI optin for email ${this.globalDataService.email}`);
      const coiOptInConfirmation = this.utilityService.getContentByOfferIdAndType(this.globalDataService.asset.sourceOfferId,
        'CoiOptInConfirmation');
      let coiOptInConfirmationAssetId = '';

      if (coiOptInConfirmation) {
        coiOptInConfirmationAssetId = coiOptInConfirmation.sourceParentAssetId;
      }
      await this.processSingleOptinUtil(event, coiOptInConfirmationAssetId, 'coi');
    } else {
      this.logger.log(`Started Processing Single optin for email ${this.globalDataService.email}`);
      await this.processSingleOptinUtil(event, '', 'single');
    }
  }

  private redirectToPromoUrl() {
    if (this.globalDataService.site && this.globalDataService.site.redirectPromotionalEffortUrl) {
      let redirectUrl = this.utilityService.updateQueryStringParamForUrl(this.globalDataService.site.redirectPromotionalEffortUrl,
        'sourceeid', this.globalDataService.queryParams.effortId);

      if (this.globalDataService.queryParams.src) {
        redirectUrl = this.utilityService.updateQueryStringParamForUrl(redirectUrl, 'src', this.globalDataService.queryParams.src);
      }
      window.location.replace(redirectUrl);
    }
  }

  public onDoubleOptinClickEvent(event: UserOptinInfo) {
    this.trackerService.track(ActivityType.click);
    this.globalDataService.email = event.email;
    const dblOptInConfirmation = this.utilityService.getContentByOfferIdAndType(
      this.globalDataService.asset.sourceOfferId,
      'DoubleOptInConfirmation'
    );
    let dblOptInConfirmationAssetId = '';
    if (dblOptInConfirmation) {
      dblOptInConfirmationAssetId = dblOptInConfirmation.sourceParentAssetId;
    }
    this.logger.log(`Started Processing Double optin for email ${this.globalDataService.email}`);
    this.isProcessing = true;
    // Dispatch Event For DoubleOptinClick
    EventService.dispatchEvent(
      EventList.DoubleOptinClick, null, event
    );
    this.siteManagerService.doubleOptIn(event, dblOptInConfirmationAssetId, '', true).subscribe(responseInfo => {
      this.logger.log(`Finished Processing Double optin for email ${this.globalDataService.email}`);
      if (responseInfo[0] != null && responseInfo[0].success) {
        this.isProcessing = false;
        event.isNewUser = (responseInfo[0].newUser) ? true : false;
        // Dispatch Event For DoubleOptinDone
        EventService.dispatchEvent(
          EventList.DoubleOptinDone, null, event
        );
        this.utilityService.loadNextAsset('Submit');
        this.globalDataService.activeSubscriber = responseInfo[0].activeSubscriber;
        this.loadAsset();
        this.trackerService.track(ActivityType.conv);
      } else {
        if (responseInfo[0] != null && responseInfo[0].error && responseInfo[0].error === 'InvalidEmail') {
          $('.sna-email-invalid').show();
        }
      }
    }, error => {
      this.logger.error(`Finished Processing Double optin for email ${this.globalDataService.email}`, error.error);
    });
  }

  private loadAsset() {
    try {
      const assetLocation = this.getAssetLocation();
      // if there are no other asset then we clearStyles.
      // otherwise to clear style after we get the new asset and load it to reduce the times getting the new asset
      if (!this.globalDataService.asset || !assetLocation) {
        // clear All css
        this.clearStyles();
      }
      this.logger.log('Cleared out old Asset');
      if (this.globalDataService.asset) {
        if (assetLocation) {
          this.isProcessing = true;
          this.siteManagerService.getAsset(assetLocation).subscribe(responseInfo => {
            if (responseInfo.indexOf('sna-email') > 0) {
              this.globalDataService.isCoreg = true;
            }
            this.isProcessing = false;
            // clear All css
            this.clearStyles();
            // we receive a json object, we have to extract the string
            this.setUpHtmlContentJavascriptAndCss().subscribe(() => {
              try {
                this.processDoubleOptInConfirmation();
                this.processCoiOptInConfirmation();
                this.processSingleOptInConfirmation();
                this.trackerService.track(ActivityType.imp);
                enableLinkClickTracking();
                trackPageView();
                this.logger.log(`Finished loading of asset  ${assetLocation} `);
              } catch (error) {
                this.logger.error('Error loading asset ', error);
              }
            }, error => {
              this.logger.error('Error subscribing to Setup HTML JS & CSS Content. ', error);
            });
          }, error => {
            // clear All css
            this.clearStyles();
            this.logger.error('Error getting asset from the s3. ', error.error);
          });
        }
      }
    } catch (error) {
      this.logger.error('Exception loading asset ', error);
    }
  }

  public onInvalidUserInfo(invalidFields: { selector: any, field: string }) {
  }

  private setupSiteDetails() {
    this.isProcessing = true;
    this.siteManagerService.getSiteDetails().subscribe(responseInfo => {
      try {
        this.isProcessing = false;
        this._siteDetail = responseInfo;
        if (this._siteDetail) {
          this.globalDataService.site = this._siteDetail;
          this.loadSite();
        }
      } catch (error) {
        this.logger.error('Exception getting site details. ', error);
      }
    }, error => {
      this.logger.error('Error Subscribing to get site details. ', error.error);
    });
  }

  private loadSite() {
    const selOfferChainId = this.utilityService.getSelectedOfferChainId(this._siteDetail);
    if (this._siteDetail) {
      this.insertCookieConsentScript(this.setUpOsanoListner);
      if (this._siteDetail && this._siteDetail.isCampaignArchived) {
        this.loadArchiveOrOfferExpiryPage();
      } else {
        this.utilityService.getAssetDetail(this._siteDetail, selOfferChainId);
        this.externalJs.triggerExternalJsFiles();
        this.loadAsset();
        this.trackerService.track(ActivityType.landingPage);
        this.loadFavicon();
        this.loadPageTitle();
        this.initTrackingContainerScripts();
        this.loadBSSPixel(this.globalDataService.site.brandId);
      }
    }
  }

  private loadArchiveOrOfferExpiryPage() {
    this.getCampaignArchiveOrOfferExpiredPage();
  }

  private getCampaignArchiveOrOfferExpiredPage() {
    let isArchivedUrlEmpty = '';
    if (this._siteDetail.archivedCampaignRedirectUrl) {
      isArchivedUrlEmpty = this._siteDetail.archivedCampaignRedirectUrl;
    }
    if (isArchivedUrlEmpty && !this.utilityService.contains(this._siteDetail.archivedCampaignRedirectUrl,
      this.globalDataService.queryParams.campaignId)) {
      window.top.location.href = this._siteDetail.archivedCampaignRedirectUrl;
    } else {
      this.getOfferExpiryPageContent();
    }
    return null;
  }

  private getOfferExpiryPageContent() {
    let expiryPage = '';
    if (this._siteDetail.brandExpiryUrl) {
      expiryPage = this._siteDetail.brandExpiryUrl;
    } else {
      expiryPage = this.getDefaultOfferExpiryPage();
    }
    this.getOfferExpiredPage(expiryPage).subscribe((data: string) => {
      if (data) {
        this.htmlContent = data;
        // this.setHtmlContent(data);
      }
    }, error => {
      console.log(error);
    });
  }

  private getDefaultOfferExpiryPage() {
    return environment.OFFER_EXPIRY_PAGE;
  }

  getOfferExpiredPage(expiryPage) {
    return this.http.get(expiryPage, {responseType: 'text'});
  }

  private initTrackingContainerScripts() {
    this.loadSnowPlowSdk();
    this.loadGoogleTagManager();
    this.loadMixPanel();
  }

  private loadGoogleTagManager() {
    if (this._siteDetail.googleTagManagerId) {
      loadGTMScript(this._siteDetail.googleTagManagerId);
    }
  }

  private loadMixPanel() {
    if (this._siteDetail.mixPanelId) {
      mixpanel.init(this._siteDetail.mixPanelId);
    }
  }

  private loadPageTitle() {
    if (this._siteDetail.storePageTitle) {
      this.setTitle(this._siteDetail.storePageTitle);
    }
  }

  private loadFavicon() {
    if (this._siteDetail.storeFavionUrl) {
      this.setAppFavicon(this._siteDetail.storeFavionUrl);
    }
  }

  private setAppFavicon(path: string) {
    $('#faviconLogo').attr('href', path);
  }

  private setUpHtmlContentJavascriptAndCss() {
    return new Observable(subscriber => {
      try {
        const setupCampaignCss$ = this.loadCampaignCss();
        const setupCampaignJs$ = this.loadCampaignJs();
        const setupExternalCss$ = this.setupExternalCss();
        const setupExternalJavascript$ = this.setupExternalJavascript();
        const setupCssLinks$ = this.setupInlineCss();
        const setupJavascriptLinks$ = this.setupInlineJavascript();
        const setupHtml$ = this.setupPageHtml();

        const setupLandingPage$ = concat(
          setupCampaignCss$,
          setupCampaignJs$,
          setupExternalCss$,
          setupCssLinks$,
          setupHtml$,
          setupExternalJavascript$,
          setupJavascriptLinks$
        );
        let doneCounter = 0;
        setupLandingPage$.subscribe(
          () => {
            doneCounter++;
            if (doneCounter === 5) {
              setTimeout(() => {
                subscriber.next();
                subscriber.complete();
              }, 300);
            }
          },
          (error) => {
            this.logger.error('Error Setting up Landing page. ', error);
          }
        );
      } catch (error) {
        this.logger.error('Exception from setting up Page Content. ', error);
      }
    });
  }

  private loadCampaignCss() {
    return new Observable(subscriber => {
      try {
        const campaignCssLinks = SiteManagerComponent.parseCampaignAssetLinks(this.globalDataService.site.campaignCss);

        campaignCssLinks.forEach(cssLink => {
          const idAttribute = Md5.hashStr(cssLink).toString();
          this.addCss(idAttribute, cssLink.trim());
        });

        subscriber.next();
      } catch (error) {
        console.error('Failed to add campaign css: ', error);
        subscriber.error(error);
      } finally {
        subscriber.complete();
      }
    });
  }

  private loadCampaignJs() {
    return new Observable(subscriber => {
      try {
        const campaignJsLinks = SiteManagerComponent.parseCampaignAssetLinks(this.globalDataService.site.campaignJs);

        campaignJsLinks.forEach((jsLink, index) => {
          const idAttribute = 'campaignJs' + index;
          this.addJs(idAttribute, jsLink.trim(), 'top');
        });

        subscriber.next();
      } catch (error) {
        console.error('Failed to add campaign js: ', error);

        subscriber.error(error);
      } finally {
        subscriber.complete();
      }
    });
  }

  private setupExternalCss() {
    return new Observable(subscriber => {
      try {
        this.currentCssLinks = [];
        const links = this.globalDataService.asset.destExternalCss;
        if (links) {
          this.currentCssLinks = this.parseAssetExternalField(links);
          if (this.currentCssLinks) {
            this.currentCssLinks.forEach(cssLink => {
              if (cssLink && cssLink.uri) {
                const fileHash = Md5.hashStr(cssLink.uri).toString();
                this.addCss(fileHash, cssLink.uri);
              }
            });
          }
        }
        subscriber.next(this.currentCssLinks);
        subscriber.complete();
      } catch (error) {
        subscriber.error(error);
        subscriber.complete();
      }
    });
  }

  private setupExternalJavascript() {
    return new Observable(subscriber => {
      try {
        this.currentJsLinks = [];
        const jsUrls = this.globalDataService.asset.destExternalJavacript;
        if (jsUrls) {
          this.currentJsLinks = this.parseAssetExternalField(jsUrls);
          if (this.currentJsLinks) {
            this.currentJsLinks.forEach(jsLink => {
              if (jsLink && jsLink.uri) {
                const fileHash = Md5.hashStr(jsLink.uri).toString();
                this.addJs(fileHash, jsLink.uri, jsLink.position);
              }
            });
          }
        }
        subscriber.next(this.currentJsLinks);
        subscriber.complete();
      } catch (error) {
        subscriber.error(error);
        subscriber.complete();
      }
    });
  }

  private setupInlineJavascript() {
    return new Observable(subscriber => {
      try {
        this.currentJsInline = '';
        const inlineJavascript = this.globalDataService.asset.destInlineJavascript;
        if (inlineJavascript) {
          const fileHash = Md5.hashStr(inlineJavascript).toString();
          const inlineJSNoCache = this.utilityService.getVersionedUrl(inlineJavascript);
          this.addJs(fileHash, inlineJSNoCache, 'top');
        }
        subscriber.next(this.currentJsLinks);
        subscriber.complete();
      } catch (error) {
        subscriber.error(error);
        subscriber.complete();
      }
    });
  }

  private setupInlineCss() {
    return new Observable(subscriber => {
      try {
        this.currentCssInline = '';
        const inLineCss = this.globalDataService.asset.destInlineCss;
        if (inLineCss) {
          if (inLineCss.includes('.css')) {
            const fileHash = Md5.hashStr(inLineCss).toString();
            const inLineCssNoCache = this.utilityService.getVersionedUrl(inLineCss);
            this.addCss(fileHash, inLineCssNoCache);
          } else {
            this.currentCssInline = atob(inLineCss);
            if (this.currentCssInline) {
              this.addInLineCss(this.currentCssInline);
            }
          }
        }
        subscriber.next(this.currentCssInline);
        subscriber.complete();
      } catch (error) {
        subscriber.error(error);
        subscriber.complete();
      }
    });
  }

  private static parseCampaignAssetLinks(links: string): string[] {
    if (links) {
      return links.trim().split(this.CAMPAIGN_LINKS_SEPARATOR);
    }

    return [];
  }

  private parseAssetExternalField(field: string) {
    if (field) {
      return JSON.parse(atob(field));
    }
    return field;
  }

  private setupPageHtml() {
    return new Observable(subscriber => {
      try {
        const assetUrl = this.globalDataService.asset.destAssetLocation;
        this.siteManagerService.getAsset(assetUrl).subscribe(htmlContent => {
          this.htmlContent = render(htmlContent, {activeSubscriber: this.globalDataService.activeSubscriber}, null);
          this.appendUrl(this.globalDataService.asset.destParentAssetId);
          this.externalJs.fireReloadAssetData();
          subscriber.next();
          subscriber.complete();
        }, error => {
          subscriber.error(error.error);
          subscriber.complete();
        });
      } catch (error) {
        subscriber.error(error);
        subscriber.complete();
      }
    });
  }

  private addInLineCss(cssScript: string) {
    const head = document.getElementsByTagName('head')[0];
    const styleElement = document.createElement('style');
    styleElement.textContent = cssScript;
    styleElement.type = 'text/css';
    head.appendChild(styleElement);
  }

  private addCss(id: string, cssLink: string) {
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = cssLink;
    link.id = id;
    head.appendChild(link);
    this.styleIds.push(id);
  }

  private addJs(id: string, jsUrl: string, position: string) {
    const script = document.createElement('script');
    script.src = jsUrl;
    script.id = id;
    if (position === 'top') {
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(script);
    } else {
      document.body.appendChild(script);
    }
    if (id) {
      this.styleIds.push(id);
    }
  }

  private clearStyles() {
    if (this.styleIds == null) {
      this.styleIds = new Array<string>();
      return;
    }

    this.styleIds.forEach(id => {
      const styleElement = document.getElementById(id);
      if (styleElement) {
        styleElement.parentNode.removeChild(styleElement);
      }
    });
  }

  private processDoubleOptInConfirmation() {
    if (this.globalDataService.queryParams.doubleOptInParams) {
      this.siteManagerService
        .doubleOptIn(null, this.globalDataService.queryParams.assetId, this.globalDataService.queryParams.doubleOptInParams, false)
        .subscribe(responseInfo => {
          // ToDo Check response and  show error message.
          if (this.globalDataService.site && this.globalDataService.site.redirectPromotionalEffortUrl) {
            this.redirectToPromoUrl();
          }
          if (responseInfo && responseInfo.encryptedSnaid) {
            this.globalDataService.encryptedSnaid = responseInfo.encryptedSnaid;
          }
        }, error => {
          this.logger.error(`Error Processing Double Optin confirmation for email ${this.globalDataService.user.email}`, error);
        });
    }
  }

  private processCoiOptInConfirmation() {
    if ((this.globalDataService.queryParams.coi || this.globalDataService.queryParams.coiParams) && this.globalDataService.user) {
      this.siteManagerService.coiOptIn(this.globalDataService.user, '', false).subscribe(responseInfo => {
        // ToDo Check response and  show error message.
        // Dispatch Event For SingleOptinClick
        if (responseInfo && responseInfo.encryptedSnaid) {
          this.globalDataService.encryptedSnaid = responseInfo.encryptedSnaid;
        }
        EventService.dispatchEvent(
          EventList.CoiOptinDone, null, {
            'email': this.globalDataService.user.email,
            'firstName': this.globalDataService.user.firstName,
            'lastName': this.globalDataService.user.lastName,
            'street': this.globalDataService.user.street,
            'city': this.globalDataService.user.city,
            'state': this.globalDataService.user.state,
            'zipcode': this.globalDataService.user.zipcode,
            'country': this.globalDataService.user.country,
            'effortId': this.globalDataService.queryParams.effortId,
            'campaignId': this.globalDataService.queryParams.campaignId
          }
        );
      }, error => {
        this.logger.error(`Error Processing COI Optin confirmation for email ${this.globalDataService.user.email}`, error);
      });
    }
  }

  private processSingleOptInConfirmation() {
    if (this.globalDataService.user && this.globalDataService.user.email && !this.globalDataService.isCoreg && !this.globalDataService.queryParams.coi) {
      const validEmail = this.utilityService.isValidEmail(this.globalDataService.user.email);
      if (validEmail) {
        this.siteManagerService.singleOptIn(this.globalDataService.user, '').subscribe(responseInfo => {
          if (this.globalDataService.site && this.globalDataService.site.redirectPromotionalEffortUrl) {
            this.redirectToPromoUrl();
          }
          if (responseInfo && responseInfo.encryptedSnaid) {
            this.globalDataService.encryptedSnaid = responseInfo.encryptedSnaid;
          }
        }, error => {
          this.logger.error(`Error Processing Single Optin confirmation for email ${this.globalDataService.user.email}`, error);
        });
      }
    } else if (this.globalDataService.queryParams.oneClick && this.globalDataService.queryParams.oneClick === 'true') {
      this.redirectToPromoUrl();
    }
  }

  private getAssetLocation() {
    let assetLocation = '';
    if (this.globalDataService.queryParams.doubleOptInParams && this.globalDataService.asset.sourcePageRedirectUrl) {
      assetLocation = this.globalDataService.asset.sourcePageRedirectUrl;
    } else {
      assetLocation = this.globalDataService.asset.destAssetLocation;
    }
    this.logger.log(`Start loadAsset of type ${this.globalDataService.asset.destAssetType} asset location ${assetLocation} `);
    return assetLocation;
  }

  private appendUrl(value: string) {
    this.utilityService.updateQueryStringParam('assetId', value);
  }

  private async processSingleOptinUtil(event: UserOptinInfo, coiOptInConfirmationAssetId: string, optinType: string) {
    this.isProcessing = true;
    this.siteManagerService.singleOptIn(event, coiOptInConfirmationAssetId).subscribe(responseInfo => {
      this.logger.log(`Finished Processing ${optinType} optin for email ${this.globalDataService.email}`);
      this.isProcessing = false;
      if (responseInfo[0] != null && responseInfo[0].success) {
        if (responseInfo[0].encryptedSnaid) {
          this.globalDataService.encryptedSnaid = responseInfo[0].encryptedSnaid;
        }
        this.trackerService.track(ActivityType.conv);
        this.utilityService.loadNextAsset('Submit');
        if (optinType !== 'coi') {
          event.isNewUser = (responseInfo[0].newUser) ? true : false;
          // Dispatch Event For SingleOptinDone
          EventService.dispatchEvent(
            EventList.SingleOptinDone, null, event
          );
        }
        if (this.globalDataService.site && this.globalDataService.site.redirectPromotionalEffortUrl) {
          this.redirectToPromoUrl();
        } else {
          this.globalDataService.activeSubscriber = responseInfo[0].activeSubscriber;
          this.loadAsset();
        }
      } else {
        const responseError = responseInfo[0] && responseInfo[0].error;
        if (responseError === 'InvalidEmail') {
          $('.sna-email-invalid').show();
        }
      }
    }, error => {
      this.logger.error(`Error Prcoessing ${optinType} optin for email ${this.globalDataService.email} `, error.error);
    });
  }

  private createScriptTag(src, id, brandId) {
    if (id && src) {
      const head = document.getElementsByTagName('head').item(0);
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.id = id;
      if (brandId) {
        script.setAttribute('data-brandId', brandId);
        // tslint:disable-next-line:radix
        const brandInt = parseInt(brandId);
        script.setAttribute('data-affiliateId', `${(brandInt - (brandInt % 100))}`);
      }

      head.appendChild(script);
    }
  }

  private loadBSSPixel(brandId) {
    this.createScriptTag(environment.BSS_PIXEL_SRC, 'bss-pixel', brandId);
  }

  private insertCookieConsentScript(callBack) {
    if (this._siteDetail.cookieConsentDomainScriptId) {
      const head = document.getElementsByTagName('head')[0];
      const script = document.createElement('script');
      script.src = this._siteDetail.cookieConsentDomainScriptId;
      script.onload = callBack;
      head.insertBefore(script, head.firstChild);
    }
  }

  private setUpOsanoListner() {
    Osano.cm.addEventListener('osano-cm-initialized', () => {
      console.log('[osano] script initialized...');
      if (Osano.cm.countryCode === 'us') {
          console.log('[osano] hiding dialog...');
          Osano.cm.hideDialog();
      }
    });
  }

  private loadSnowPlowSdk() {
    newTracker('sp1', environment.SNOW_PLOW_COLLECTOR_URL, {
        appId: `leadgen-ui-${environment.env}`,
        discoverRootDomain: true,
        cookieSameSite: 'Lax',
        contexts: {
            performanceTiming: true as never,
        },
        stateStorageStrategy: 'localStorage',
        plugins: [PerformanceTimingPlugin(), FormTrackingPlugin(), LinkClickTrackingPlugin()],
    });
    this.loadSnowPlowActivities();
    this.setSnowPlowSeesionIdToGlobalData();
  }

  private loadSnowPlowActivities() {
    this.spEnableActivityTracking();
    this.spEnableFormTracking();
  }

  private spEnableActivityTracking() {
    enableActivityTracking({
        minimumVisitLength: 5,
        heartbeatDelay: 15
    });
  }

  private spEnableFormTracking() {
    enableFormTracking({
        options: formOptions
    });
  }

  private setSnowPlowSeesionIdToGlobalData() {
    for (const [key, value] of Object.entries(localStorage)) {
      try {
        if (key.startsWith('_sp_id') && key.indexOf('expires') < 0) {
          const sessionId = value.split('.')[5];
          this.globalDataService.snowplowSessionId = sessionId;
        }
      } catch(error) {
        console.error(`error fetching sp session id ${error}`);
      }
    }
  }
}
